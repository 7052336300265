/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
/*
.theme-form .form-group {
    margin-bottom: 5px!important;
}*/

/*** Navbar ***/
.navbar-dark .navbar-nav .nav-link {
    font-family: 'Nunito', sans-serif;
    position: relative;
    margin-left: 25px;
    padding: 35px 0;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 600;
    outline: none;
    transition: .5s;
}

.sticky-top.navbar-dark .navbar-nav .nav-link {
    padding: 20px 0;
    color: var(--dark);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
    color: var(--primary);
}

.navbar-dark .navbar-brand h1 {
    color: #FFFFFF;
}

.navbar-dark .navbar-toggler {
    color: var(--primary) !important;
    border-color: var(--primary) !important;
}


@media (min-width: 992px) {
    .navbar-dark {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        border-bottom: 1px solid rgba(256, 256, 256, .1);
        z-index: 999;
    }
    
    .sticky-top.navbar-dark {
        position: fixed;
        background: #FFFFFF;
    }

    .navbar-dark .navbar-nav .nav-link::before {
        position: absolute;
        content: "";
        width: 0;
        height: 2px;
        bottom: -1px;
        left: 50%;
        background: var(--primary);
        transition: .5s;
    }

    .navbar-dark .navbar-nav .nav-link:hover::before,
    .navbar-dark .navbar-nav .nav-link.active::before {
        width: 100%;
        left: 0;
    }

    .navbar-dark .navbar-nav .nav-link.nav-contact::before {
        display: none;
    }

    .sticky-top.navbar-dark .navbar-brand h1 {
        color: var(--primary);
    }
}


/*** Carousel ***/
.carousel-caption {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(9, 30, 62, .7);
    z-index: 1;
}


.carousel-control-prev,
.carousel-control-next {
    width: 10%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    width: 3rem;
    height: 3rem;
}


/*** Miscellaneous ***/
@media (min-width: 991.98px) {
    .facts {
        position: relative;
        margin-top: -75px;
        z-index: 1;
    }
}

.back-to-top {
    position: fixed;
    display: none;
    right: 45px;
    bottom: 45px;
    z-index: 99;
}

.bg-header {
    background: linear-gradient(rgba(9, 30, 62, .7), rgba(9, 30, 62, .7)), url(assets/images/carousel-1.jpg) center center no-repeat;
    background-size: cover;
}

.link-animated a {
    transition: .5s;
}

.link-animated a:hover {
    padding-left: 10px;
}

@media (min-width: 767.98px) {
    .footer-about {
        margin-bottom: -75px;
    }
}


.overlay {
    position: fixed; /* Usar fixed para cubrir toda la pantalla */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(74, 74, 74, 0.8); /* Color de fondo con transparencia */
    z-index: 10; /* Asegúrate de que el overlay esté encima del modal */
    display: flex; /* Usar flex para centrar el contenido */
    align-items: center; /* Centrar verticalmente */
    justify-content: center; /* Centrar horizontalmente */
}
/* Styles for the modal window */
.custom-modal .modal-content {
    /* Estilos específicos para el contenido del modal */
    background: none;
    border: none;
    /* Agrega más estilos según sea necesario */
  }
  
  .custom-modal-class-01 .modal-content {
    min-height: 600px!important; /* Ajusta el valor según tus necesidades */
  }
  
   
  .custom-modal-class-02 .modal-content {
    min-height: 400px!important; /* Ajusta el valor según tus necesidades */
  }
  
 
  .custom-modal .modalClass .spinner-border {
    width: 5rem !important;
    height: 5rem !important;
    border-width: 0.5rem;
    border-color: white;
    border-right-color: transparent;
  }
 
  .lds-default,
.lds-default div {
  box-sizing: border-box;
}

.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-default div {
  position: absolute;
  width: 6.4px;
  height: 6.4px;
  background: currentColor;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}

.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 36.8px;
  left: 66.24px;
}

.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22.08px;
  left: 62.29579px;
}

.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11.30421px;
  left: 51.52px;
}

.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7.36px;
  left: 36.8px;
}

.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11.30421px;
  left: 22.08px;
}

.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22.08px;
  left: 11.30421px;
}

.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 36.8px;
  left: 7.36px;
}

.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 51.52px;
  left: 11.30421px;
}

.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62.29579px;
  left: 22.08px;
}

.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66.24px;
  left: 36.8px;
}

.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62.29579px;
  left: 51.52px;
}

.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 51.52px;
  left: 62.29579px;
}

@keyframes lds-default {

  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }
}

input.form-control {
  text-transform: uppercase;
}

/* Poner en negrita el label cuando el campo dentro de .form-group está en foco */
.form-group:focus-within label {
  font-weight: bold;
  /* Hacer el texto del label en negrita */
}

h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  color: black;
}